import React from "react";
import PageBanner from "../../components/PageBanner";

import useScrollUp from "../../utilities/useScrollUp";


function Winners  () {

  useScrollUp()
  return (
  <React.Fragment>
    <PageBanner heading="Trophy Winners" tagline="Sponsor awarded trophies" backgroundImage="../../images/winners/24trophyBanner.png"/>
    <div className="container">
      <h1>Sponsor Selected</h1>
        <p>
          Sponsors not only provide the trophy they also get to award their trophy to the vehicle they like best... no guidelines, no limits, ALL FUN!
          <br/>
          Below are photos of the sponsors and their picks for best car of 2024
        </p>
          <div className="row">
            
            
          </div> 
          <hr/>
          
         
      </div>
    </React.Fragment>
  );
}


export default Winners ;