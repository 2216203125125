import React from "react";
import { Link } from "react-router-dom";
import MailToLink from "../components/MailToLink";
import PageBanner from "../components/PageBanner";
import useScrollUp from "../utilities/useScrollUp";


function Art() {

  useScrollUp()
  return (
    <React.Fragment>
      <PageBanner heading="Event Art" tagline="by Elise Durenberger" backgroundImage="images/art/dgBanner.jpg"/>
      <div className="container"> 

        <h1>Take home your own event art!</h1>
        <p>In collaboration with local fine artist, Elise Durenberger, Cruisin' the Boulevard proudly presents original and reproduction event artwork for sale!  Many thanks to Digital Grange Fine Art Services for your scanning and printing support! 
        </p>
        <div className="row">
          <div className="col-3-sm rounded mx-auto d-block">
            <img src="images/art/art2025.png" alt="2025 art poster"/>
          </div>
          <div className="col-9-sm">
            <p>Avilable now for purchase</p>
            <ul>
              <li>
                Limited Edition Archival Canvas 24" x 30" Reproduction   
                <br/>
                25 made, embellished and signed by artist Elise Durenberger.
                <br/>
                $475.00 + Tax and shipping *if applicable
                <br/>
              </li>
              <li>
                Limited Edition Pigment Print 14x18 rag paper 
                <br/>
                100 made and signed by artist Elise Durenberger.
                <br/>
                $55.00 + Tax and shipping *if applicable
                <br/>
              </li>
            </ul>
            <p className="right">
              To purchase contact <MailToLink label="mamacow@comcast.net" mailto="mailto:mamacow@comcast.net" />
              <br/>
              *Free local delivery or pickup at event
            </p>
          </div>
        </div>
        <br/>
        <section>
          <h2>Artist Elise A. Durenberger</h2>
          <img className="wrapRight" src="images/art/elise25.png" alt="Artist Elise Durenberger"/>
          <p>
            Elise Durenberger is celebrating her 10th painting for Cruisin’ the Boulevard in bright and bold, classic car style. This year’s scene delivers a new perspective with a nod to the movie we all love, a welcome embrace to modern Petaluma and our signature sponsor, Capitol Electric. If you followed Elise’s work since 2014, she has always embraced the movie car characters, first and foremost. Marrying the iconic Petaluma downtown, as those of us who live here know and love, with some of the flashiest and notable cars from American Graffiti with slight adjustments to actual cityscape is a daunting, but thrilling challenge for Elise. A Sonoma County proud resident, Elise studied fine art at UC Davis and has been showcasing her love for our area through her paintings for the past two decades. Much of her work is available for purchase in local businesses, including Westamerica Bank in Petaluma and Johnny’s Java Coffee in Cotati.
            <br/>
            <br/>
          </p>
          <p className="right">For more information please visit:  <Link to={{ pathname: "https://www.elisedurenbergerfineart.com"}} target="_blank">www.elisedurenbergerfineart.com</Link>
            <br/>
            or contact her directly at <MailToLink label="mamacow@comcast.net" mailto="mailto:mamacow@comcast.net" />
          </p>
          <br/>
          <br/>
        </section>
        <section>
          <h2>Past Event Art</h2>
          <div className="row">
            <div className="col-3">
              <img src="images/art/art2024.png" alt="2024 art poster"/>
              <h5 className="center">2024</h5>
              <p className="left">art pieces still available</p>
            </div>
            <div className="col-3">
              <img src="images/art/art2023.png" alt="2023 art poster"/>
              <h5 className="center">2023</h5>
              <p className="left">art pieces still available</p>
            </div>
            <div className="col-3">
              <img src="images/art/art2020.jpg" alt="2020 art poster"/>
              <h5 className="center">2020</h5>
              <p className="left">art pieces still available</p>
            </div>
            <div className="col-3">
              <img src="images/art/art2019.jpg" alt="2019 art poster"/>
              <h5 className="center">2019</h5>
              <p className="left">art pieces still available</p>
            </div>
            <div className="col-3"></div>
          </div>
          <div className="row">
            <div className="col-3">
              <img src="images/art/art2018.jpg" alt="2018 art poster"/>
              <h5 className="center">2018</h5>
              <p className="left">art pieces still available</p>
            </div>
            <div className="col-3">
              <img src="images/art/art2017.jpg" alt="2017 art poster"/>
              <h5 className="center">2017</h5>
              <p className="left">art pieces still available</p>
            </div>
            <div className="col-3">
              <img src="images/art/art2016.jpg" alt="2016 art poster"/>
              <h5 className="center">2016</h5>
              <p className="left">art pieces still available</p>
            </div>
            <div className="col-3">
              <img src="images/art/art2015.jpg" alt="2015 art poster"/>
              <h5 className="center">2015</h5>
              <p className="left">art pieces still available</p>
            </div>
          </div>
          <div className='row'>
            <div className="col-3">
              <img src="images/art/art2014.jpg" alt="2014 art poster"/>
              <h5 className="center">2014</h5>
              <p className="left"></p>
            </div>
          </div>
          <p className="right">
            To purchase past event art contact <MailToLink label="mamacow@comcast.net" mailto="mailto:mamacow@comcast.net" />
          </p>
        </section>
        <section>
          <h2>Digital Grange</h2>
          <Link to={{ pathname: "https://www.digitalgrange.com/"}} target="_blank"><img className="wrapLeft" src="images/art/digitalGrangeLogo.png" alt="digital grange logo"/></Link>
          <p>
            The process starts with a scan or photograph of the original artwork. Scanning and photography mean the same thing.  A camera is used but rather than capturing the image on film it is instead captured to the computer via the sensor on the back of the camera.  Digital Grange also has a high-end flatbed scanner which can be used for tabloid or smaller sized artwork. Each scan is profiled, then processed or “developed” and carefully evaluated to ensure the colors on the screen match the original.  It is then proofed (printed) on a piece of paper or canvas depending on what the artist wants as a substrate.
            <br/>
            <br/>
            Digital Grange can custom print on virtually any surface (paper, canvas, wood, fabric, plaster, etc.) imaginable. This proof is then checked against the original with the artist in attendance so that not only the color matches the original but the “feel” of the art is captured. Once the artist approves the image is printed on the substrate with archival pigmented inks on an archival substrate. It is truly a custom process as we are not interested in mass printings but rather fine, detailed and entirely accurate reproductions of the original, to the point where many can’t really tell the difference. Once the file is approved the artist signs off on the proof and the file is archived and can be printed any time in the future and will not change. This enables the artist to “print” on demand, so to speak. The prints are called pigment prints and are printed on our Hewlett Packard 12 ink custom inkjet printers that can print up to 60 inches wide and as long as needed. 
          </p>
        </section>
      </div>
    </React.Fragment>
  );
}

export default Art;